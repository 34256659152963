import React from "react";
import { Link, Script } from "gatsby";
import { Disclosure } from "@headlessui/react";
import ConvertKit from "../components/convertkit-form";
import Seo from "../components/seo";
import Wave from "../components/wave";
import {
  SparklesIcon,
  LanguageIcon,
  ArrowDownOnSquareStackIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import LazyVideo from "../components/lazy-video";
import LazyIframe from "../components/lazy-iframe";
import { StaticImage } from "gatsby-plugin-image";
import Testimonial from "../components/testimonial";
import CountUp from "react-countup";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const IndexPage = () => {
  const faq = [
    {
      question: "What is the Contentful to Figma plugin?",
      answer:
        "It is a Figma plugin that allows you to easily integrate Contentful and Figma in your workflow. It’s never been easier to share your work with your team and clients.",
    },
    {
      question: "How does it work?",
      answer:
        "The plugin allows you to easily import your Contentful content into Figma. You can then use the content in your designs and share your work with your team and clients.",
    },
    {
      question: "What is the difference between the free and paid version?",
      answer:
        "The free version allows you to perform basic content import from Contentful. The paid version allows you to import unlimited entries from Contentful, share your settings with your team and supports multi locales.",
    },
    {
      question: "How do I install the plugin?",
      answer: (
        <span>
          To install the plugin, follow these steps: Log in to your Figma
          account Go to the plugin page (
          <a
            className="text-teal-700 hover:text-teal-900"
            href="https://www.figma.com/community/plugin/789594601370719857/Contentful-to-Figma"
          >
            https://www.figma.com/community/plugin/789594601370719857/Contentful-to-Figma
          </a>
          ) Click the "Install" button.
        </span>
      ),
    },
    {
      question: "How do I use this Figma plugin?",
      answer: `To use the plugin, follow these steps: Open a Figma file Select the "Plugins" menu in the top menu bar Select "Contentful to Figma" from the list of plugins Follow the prompts to connect to your Contentful account and import your content I'm having trouble connecting to my Contentful account. What should I do?

      If you are having trouble connecting to your Contentful account, please make sure that you have entered the correct API keys and that you have granted the necessary permissions to the plugin.
      
      If you continue to have trouble, please contact us at support@contentfultofigma.com for assistance.`,
    },
    {
      question: "Can I use the plugin with a Contentful free account?",
      answer:
        "Yes, you can use the plugin with a Contentful trial account. However, please note that some features may be limited or unavailable.",
    },
    {
      question: "What to do with the license after my purchase?",
      answer:
        "After your purchase, you will receive an email with your license key. You can then enter the license key in the plugin settings to activate your license.",
    },
  ];
  return (
    <div className="bg-gray-100 pt-10">
      {/* ts-ignore */}
      <Script src="https://gumroad.com/js/gumroad.js" />
      <Script
        dangerouslySetInnerHTML={{
          __html: `window.$crisp=[];window.CRISP_WEBSITE_ID="5a32e688-748c-492b-b1fb-53ae8148d06b";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`,
        }}
      />

      <div className=" bg-gray-100 ">
        <header className="flex justify-between items-center max-w-2xl mx-auto border-b mb-5 px-4 sm:px-6 lg:px-8">
          <h1 className="text-xl font-bold text-gray-800 mb-4 flex items-center justify-center">
            <StaticImage
              src="../images/contentful-to-figma-logo.png"
              alt="Contentful to Figma plugin logo"
              width={56}
              height={56}
            />
            Contentful to Figma
          </h1>
          <span className="text-gray-700 -mt-3 block">Plugin for Figma</span>
        </header>
        <section className="max-w-4xl mx-auto">
          <div className="px-4 sm:px-6 lg:px-8">
            <h2 className="text-gray-900 mb-2 text-3xl text-center mt-5">
              Create, Build & Share Your Workflow
            </h2>
            <p className="text-gray-700 mb-8 text-center">
              Sync your content and design teams with the Contentful to Figma
              plugin.
            </p>
          </div>
        </section>

        <section className="max-w-2xl pb-6 mx-auto">
          <div className="mx-auto sm:flex justify-between px-4 sm:px-6 lg:px-8">
            <a
              href="https://www.figma.com/community/plugin/789594601370719857"
              target="_blank"
              className="flex mb-2 sm:mb-0 justify-center items-center space-x-3 text-teal-700 hover:text-teal-900"
            >
              <StaticImage
                src="../images/contentful-to-figma-logo.png"
                alt="Contentful to Figma plugin logo"
                width={56}
                height={56}
              />
              <span className="border-b border-teal-600 border-dashed">
                Available on Figma
              </span>
            </a>
            <a
              href="https://khaledgarbaya.gumroad.com/l/contentful-to-figma"
              data-gumroad-single-product="true"
              className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
            >
              Start Free Trial
            </a>
          </div>
        </section>

        <section className="bg-teal-600 mx-auto max-w-7xl w-full py-10 relative">
          <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <StaticImage
              src="../images/contentful-to-figma-landing-banner.png"
              alt="Contentful to Figma plugin"
              className="w-full"
              width={1280}
              height={720}
            />
          </div>
          <Wave />
        </section>
        <section className="mx-auto mt-8 bg-white p-16">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-gray-900 mb-2 text-3xl text-center mt-5">
              A Figma Plugin Loved By Designers and Developers
            </h2>
            <div className="mt-10 bg-white pb-12 sm:pb-16">
              <div className="relative">
                <div className="absolute inset-0 h-1/2 bg-gray-50" />
                <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
                  <div className="mx-auto max-w-4xl">
                    <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-2">
                      <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                        <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                          Pro Customers
                        </dt>
                        <dd className="order-1 text-5xl font-bold tracking-tight text-teal-600">
                          <CountUp
                            duration={3}
                            end={365}
                            enableScrollSpy={true}
                          />
                        </dd>
                      </div>
                      <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                        <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                          Installs
                        </dt>
                        <dd className="order-1 text-5xl font-bold tracking-tight text-teal-600">
                          1.1k
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="max-w-4xl mx-auto py-12 bg-gray-100">
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <p className="text-gray-700">
              Contentful to Figma is a game-changer for designers. Pull data
              from your Contentful CMS with ease and speed, and use it to
              generate custom design elements within Figma.
            </p>
            <ul role="list" className="mt-6 space-y-6">
              <li className="flex">
                {/* <!-- Heroicon name: outline/check --> */}
                <svg
                  className="h-6 w-6 flex-shrink-0 text-teal-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
                <span className="ml-3 text-gray-600">
                  Automate design templates using Contentful CMS content, such
                  as written product descriptions or team bios.
                </span>
              </li>

              <li className="flex">
                {/* <!-- Heroicon name: outline/check --> */}
                <svg
                  className="h-6 w-6 flex-shrink-0 text-teal-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
                <span className="ml-3 text-gray-600">
                  Ensure brand consistency by streamlining design elements, such
                  as colors or fonts, with Contentful CMS content.
                </span>
              </li>
              <li className="flex">
                {/* <!-- Heroicon name: outline/check --> */}
                <svg
                  className="h-6 w-6 flex-shrink-0 text-teal-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
                <span className="ml-3 text-gray-600">
                  Create dynamic design elements based on your Contentful CMS,
                  such as data visualizations or interactive infographics.
                </span>
              </li>
              <li className="flex">
                {/* <!-- Heroicon name: outline/check --> */}
                <svg
                  className="h-6 w-6 flex-shrink-0 text-teal-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
                <span className="ml-3 text-gray-600">
                  Improve design testing by populating design mockups with
                  accurate content from Contentful CMS.
                </span>
              </li>
              <li className="flex">
                {/* <!-- Heroicon name: outline/check --> */}
                <svg
                  className="h-6 w-6 flex-shrink-0 text-teal-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
                <span className="ml-3 text-gray-600">
                  Generate design assets, such as images or icons, based on
                  Contentful CMS data to save time and improve work efficiency.
                </span>
              </li>
            </ul>
          </div>
        </section>
        {/* features detail */}
        <section className="mt-24 bg-white">
          <div className="relative overflow-hidden pt-16 pb-32">
            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
            />
            <div className="relative">
              <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 px-6">
                <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-20 lg:px-0">
                  <div>
                    <div>
                      <span className="flex h-12 w-12 items-center justify-center rounded-md bg-gradient-to-r from-teal-600 to-teal-400">
                        <ArrowDownOnSquareStackIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div className="mt-6">
                      <h2 className="text-3xl tracking-tight text-gray-900">
                        See Your Content Live In Action
                      </h2>
                      <p className="mt-4 text-lg text-gray-600">
                        Watch as your copy or design comes to life during your
                        Figma workflow. Welcome to the future of cross-team
                        collaboration!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0">
                  <div className="lg:relative lg:m-0 lg:h-full lg:px-0">
                    <LazyVideo
                      sources={[
                        {
                          src: "/videos/create-og-images.webm",
                          type: "video/webm",
                        },
                        {
                          src: "/videos/create-og-images.mp4",
                          type: "video/mp4",
                        },
                      ]}
                      poster="/videos/create-og-image-frame.png"
                      width={610}
                      height={254}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-24">
              <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 px-6">
                <div className="mx-auto max-w-xl px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-20 lg:px-0">
                  <div>
                    <div>
                      <span className="flex h-12 w-12 items-center justify-center rounded-md bg-gradient-to-r  from-teal-600 to-teal-400">
                        <SparklesIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div className="mt-6">
                      <h2 className="text-3xl tracking-tight text-gray-900">
                        Collaborate Using Contentful Integration
                      </h2>
                      <p className="mt-4 text-lg text-gray-600">
                        From copywriters to web developers, make sure that
                        everybody on your team is on the same page. Share
                        content with your team, and watch as they create design
                        elements intuitively.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
                  <div className="lg:relative lg:m-0 lg:h-full lg:px-0">
                    <LazyVideo
                      sources={[
                        {
                          src: "/videos/share-settings-with-team.webm",
                          type: "video/webm",
                        },
                        {
                          src: "/videos/share-settings-with-team.mp4",
                          type: "video/mp4",
                        },
                      ]}
                      poster="/videos/share-settings-with-team-frame.png"
                      width={610}
                      height={254}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="relative mt-24">
              <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 px-6">
                <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-20 lg:px-0">
                  <div>
                    <div>
                      <span className="flex h-12 w-12 items-center justify-center rounded-md bg-gradient-to-r from-teal-600 to-teal-400">
                        <LanguageIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div className="mt-6">
                      <h2 className="text-3xl tracking-tight text-gray-900">
                        Generate Content in Multiple Languages
                      </h2>
                      <p className="mt-4 text-lg text-gray-600">
                        Produce content in more than one language, in less time.
                        Use Contentful CMS data to generate social media posts,
                        newsletters, web pages and more.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0">
                  <div className="lg:relative lg:m-0 lg:h-full lg:px-0">
                    <LazyVideo
                      sources={[
                        {
                          src: "/videos/locales.webm",
                          type: "video/webm",
                        },
                        {
                          src: "/videos/locales.mp4",
                          type: "video/mp4",
                        },
                      ]}
                      poster="/videos/locales-frame.png"
                      width={610}
                      height={254}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Youtube video overview */}
        <section className="mx-auto max-w-6xl bg-gray-100 py-24 px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto p-2  bg-gray-100 rounded-lg">
            <h2 className="text-gray-900 mb-5 text-4xl mt-5">
              Get Started with the Contentful to Figma Plugin
            </h2>
            <LazyIframe />
          </div>
        </section>
        {/* Testemonial */}
        <section className="bg-white">
          <div className="mx-auto max-w-6xl px-6 pt-20 pb-12 lg:px-8 lg:py-20 bg-white">
            <h2 className="text-gray-900 mb-5 text-5xl mt-5">
              Don't take our word for it
            </h2>
          </div>
          {/* CF364 */}
          <Testimonial
            fullName={"Bechir Arfaoui"}
            content={`For our copywriter, having the Contentful to Figma plugin has been a game changer for their workflow. It allows them to see the copy in context within a Figma flow or journey, which is crucial for crafting appropriate UX copywriting. Without this plugin, the copy and design would be out of sync, leading to errors and the need for constant check-ins and reviews between the design and copywriting teams.`}
            company="CF365"
            title="CEO"
            companyUrl="https://carbonfiber365.com/"
          >
            <StaticImage
              className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
              src="../images/bechir.jpg"
              width={320}
              height={320}
              alt="Bechir Arfaoui"
            />
            <StaticImage
              className="h-12 w-12 rounded-full"
              src="../images/bechir.jpg"
              width={48}
              height={48}
              alt="Bechir Arfaoui"
            />
          </Testimonial>
          {/* bytecommenrce.og */}
          <Testimonial
            fullName="Wissem Riahi"
            content={`The Contentful to Figma plugin has made it so much easier for our designers to work with our content team. It allows for real-time updates and eliminates the need for back-and-forth communication between teams. Highly recommend!`}
            company="Byte Commerce"
            title="Managing Director"
            companyUrl="https://bytecommerce.co/"
          >
            <StaticImage
              className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
              src="../images/wissem.jpeg"
              width={320}
              height={320}
              alt="Wissem Riahi"
            />
            <StaticImage
              className="h-12 w-12 rounded-full"
              src="../images/wissem.jpeg"
              width={48}
              height={48}
              alt="Bechir Arfaoui"
            />
          </Testimonial>
          {/* Simon */}
          <Testimonial
            fullName="Simon Forslund"
            content={`We are extremely pleased with the Contentful to Figma extension. The integration allows us to automatically process and import content directly from Contentful into Figma, saving us a significant amount of time every week. By reusing our content model, we can quickly generate all necessary content for e-commerce campaigns in multiple languages, including banners, images, social media and newsletters. This would previously take countless hours to complete manually. Overall, the Contentful extension has greatly improved our workflow and we highly recommend it to others.`}
            company="BRAMÖBLER"
            title="E-handle/IT"
            companyUrl="https://www.bramobler.se/"
          >
            <StaticImage
              className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
              src="../images/simon.png"
              width={320}
              height={320}
              alt="Simon Forslund"
            />
            <StaticImage
              className="h-12 w-12 rounded-full"
              src="../images/simon.png"
              width={48}
              height={48}
              alt="Simon Forslund"
            />
          </Testimonial>
        </section>
        {/* Pricing Section */}
        <section className="mx-auto">
          <div className="mx-auto max-w-6xl bg-gray-100 py-24 px-4 sm:px-6 lg:px-8">
            <h2 className="text-gray-900 mb-2 text-3xl mt-5">
              Which Pricing Plan Is Right For Me?
            </h2>
            <p className="mt-6 max-w-2xl text-xl text-gray-500">
              We've created a pricing plan for every team size. Whether you're
              just one person or multiple designers, don't waste any more time
              manually updating design elements. Try the Contentful to Figma
              plugin today, and revolutionize your workflow!
            </p>

            {/* <!-- Tiers --> */}
            <div className="mt-24 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
              <div className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm">
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Personal
                  </h3>
                  <p className="mt-4 flex items-baseline text-gray-900">
                    <span className="text-5xl font-bold tracking-tight">
                      $9.99
                    </span>
                    <span className="ml-1 text-xl font-semibold">/month</span>
                  </p>

                  {/* <!-- Feature list --> */}
                  <ul role="list" className="mt-6 space-y-6">
                    <li className="flex">
                      {/* <!-- Heroicon name: outline/check --> */}
                      <svg
                        className="h-6 w-6 flex-shrink-0 text-teal-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      <span className="ml-3 text-gray-500">2 devices</span>
                    </li>

                    <li className="flex">
                      {/* <!-- Heroicon name: outline/check --> */}
                      <svg
                        className="h-6 w-6 flex-shrink-0 text-teal-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      <span className="ml-3 text-gray-500">
                        7-days free trial
                      </span>
                    </li>

                    <li className="flex">
                      {/* <!-- Heroicon name: outline/check --> */}
                      <svg
                        className="h-6 w-6 flex-shrink-0 text-teal-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      <span className="ml-3 text-gray-500">
                        Save Settings accross devices
                      </span>
                    </li>
                    <li className="flex">
                      {/* <!-- Heroicon name: outline/check --> */}
                      <svg
                        className="h-6 w-6 flex-shrink-0 text-teal-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      <span className="ml-3 text-gray-500">
                        Locales Support
                      </span>
                    </li>

                    <li className="flex">
                      {/* <!-- Heroicon name: outline/check --> */}
                      <svg
                        className="h-6 w-6 flex-shrink-0 text-teal-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      <span className="ml-3 text-gray-500">Cancel anytime</span>
                    </li>
                  </ul>
                </div>

                <a
                  href="https://khaledgarbaya.gumroad.com/l/contentful-to-figma?option=941Mx7Bl1JdeGOSMu62JyQ%3D%3D"
                  data-gumroad-single-product="true"
                  className="bg-teal-100 cursor-pointer text-teal-700 hover:bg-teal-100 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
                >
                  Monthly billing
                </a>
              </div>

              <div className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm">
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-gray-900">Team</h3>

                  <p className="absolute top-0 -translate-y-1/2 transform rounded-full bg-teal-500 py-1.5 px-4 text-sm font-semibold text-white">
                    Most popular
                  </p>
                  <p className="mt-4 flex items-baseline text-gray-900">
                    <span className="text-5xl font-bold tracking-tight">
                      $39.99
                    </span>
                    <span className="ml-1 text-xl font-semibold">/month</span>
                  </p>

                  {/* <!-- Feature list --> */}
                  <ul role="list" className="mt-6 space-y-6">
                    <li className="flex">
                      {/* <!-- Heroicon name: outline/check --> */}
                      <svg
                        className="h-6 w-6 flex-shrink-0 text-teal-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      <span className="ml-3 text-gray-500">7 devices</span>
                    </li>

                    <li className="flex">
                      {/* <!-- Heroicon name: outline/check --> */}
                      <svg
                        className="h-6 w-6 flex-shrink-0 text-teal-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      <span className="ml-3 text-gray-500">
                        7-days free trial
                      </span>
                    </li>

                    <li className="flex">
                      {/* <!-- Heroicon name: outline/check --> */}
                      <svg
                        className="h-6 w-6 flex-shrink-0 text-teal-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      <span className="ml-3 text-gray-500">
                        Save Settings accross devices
                      </span>
                    </li>
                    <li className="flex">
                      {/* <!-- Heroicon name: outline/check --> */}
                      <svg
                        className="h-6 w-6 flex-shrink-0 text-teal-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      <span className="ml-3 text-gray-500">
                        Locales Support
                      </span>
                    </li>
                    <li className="flex">
                      {/* <!-- Heroicon name: outline/check --> */}
                      <svg
                        className="h-6 w-6 flex-shrink-0 text-teal-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      <span className="ml-3 text-gray-500">Cancel anytime</span>
                    </li>
                  </ul>
                </div>

                <a
                  href="https://khaledgarbaya.gumroad.com/l/contentful-to-figma?option=nmYBC0QB_N9AEGERD0cjsA%3D%3D"
                  data-gumroad-single-product="true"
                  className="bg-teal-500 cursor-pointer text-white hover:bg-teal-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
                >
                  Monthly billing
                </a>
              </div>

              <div className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm">
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Enterprise
                  </h3>
                  <p className="mt-4 flex items-baseline text-gray-900">
                    <span className="text-5xl font-bold tracking-tight">
                      $99.99
                    </span>
                    <span className="ml-1 text-xl font-semibold">/month</span>
                  </p>
                  {/* <!-- Feature list --> */}
                  <ul role="list" className="mt-6 space-y-6">
                    <li className="flex">
                      {/* <!-- Heroicon name: outline/check --> */}
                      <svg
                        className="h-6 w-6 flex-shrink-0 text-teal-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      <span className="ml-3 text-gray-500">
                        Unlimited devices
                      </span>
                    </li>

                    <li className="flex">
                      {/* <!-- Heroicon name: outline/check --> */}
                      <svg
                        className="h-6 w-6 flex-shrink-0 text-teal-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      <span className="ml-3 text-gray-500">
                        7-days free trial
                      </span>
                    </li>

                    <li className="flex">
                      {/* <!-- Heroicon name: outline/check --> */}
                      <svg
                        className="h-6 w-6 flex-shrink-0 text-teal-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      <span className="ml-3 text-gray-500">
                        Save Settings accross devices
                      </span>
                    </li>

                    <li className="flex">
                      {/* <!-- Heroicon name: outline/check --> */}
                      <svg
                        className="h-6 w-6 flex-shrink-0 text-teal-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      <span className="ml-3 text-gray-500">Cancel anytime</span>
                    </li>
                    <li className="flex">
                      {/* <!-- Heroicon name: outline/check --> */}
                      <svg
                        className="h-6 w-6 flex-shrink-0 text-teal-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      <span className="ml-3 text-gray-500">
                        Priority Support
                      </span>
                    </li>
                    <li className="flex">
                      {/* <!-- Heroicon name: outline/check --> */}
                      <svg
                        className="h-6 w-6 flex-shrink-0 text-teal-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      <span className="ml-3 text-gray-500">
                        Priority feature requests
                      </span>
                    </li>
                  </ul>
                </div>

                <a
                  data-gumroad-single-product="true"
                  href="https://khaledgarbaya.gumroad.com/l/contentful-to-figma?option=insK28iOvpdZZtE2RcsZvw%3D%3D"
                  className="bg-teal-50 text-teal-700 hover:bg-teal-100 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
                >
                  Monthly billing
                </a>
              </div>
            </div>
          </div>
          <div className="mx-auto max-w-md lg:max-w-4xl">
            <div className="bg-white flex flex-col gap-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 lg:flex-row lg:items-center lg:gap-8">
              <div className="lg:min-w-0 lg:flex-1">
                <h3 className="text-lg font-semibold leading-8 tracking-tight">
                  What Can I Get For Free?
                </h3>
                <div className="mt-2 text-base leading-7 text-gray-600">
                  The free tier includes basic features such as the ability to
                  import and update text, and images from Contentful to Figma.
                  Some restrictions may apply, such as sharing settings with
                  your team, or working with locales. Certain exclusive features
                  can be unlocked with a paid subscription.
                </div>
              </div>
              <div>
                <a
                  href="https://www.figma.com/community/plugin/789594601370719857"
                  className="inline-block rounded-lg bg-teal-50 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-teal-700 hover:bg-teal-100"
                >
                  Get the Free Figma Plugin{" "}
                  <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="mx-auto max-w-7xl py-12 px-6 sm:py-16 lg:px-8">
        <div className="mx-auto max-w-3xl divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl tracking-tight text-gray-900 sm:text-4xl">
            Frequently asked questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faq.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </section>
      <div className="flex max-w-4xl mx-auto">
        <div className="px-4 py-5 sm:p-6 mx-auto">
          <h3 className="text-xl font-medium leading-6 text-gray-900">
            Notify Me When There's A Discount
          </h3>
          <ConvertKit />
        </div>
      </div>
      <footer className="bg-white ">
        <div className="max-w-4xl mx-auto overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
          <nav
            className="-mx-5 -my-2 flex flex-wrap justify-center"
            aria-label="Footer"
          >
            <div className="px-5 py-2">
              <a
                href="mailto:support@contentfultofigma.com"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Contact
              </a>
            </div>
            <div className="px-5 py-2">
              <Link
                to="/blog/"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Blog
              </Link>
            </div>
            <div className="px-5 py-2">
              <Link
                to="/about/"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                About
              </Link>
            </div>
            <div className="px-5 py-2">
              <Link
                to="/buy/"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Buy
              </Link>
            </div>

            <div className="px-5 py-2">
              <Link
                to="/help/"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Help
              </Link>
            </div>

            <div className="px-5 py-2">
              <Link
                to="/terms-of-use/"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Terms
              </Link>
            </div>

            <div className="px-5 py-2">
              <Link
                to="/accessibility/"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Accessibility
              </Link>
            </div>

            <div className="px-5 py-2">
              <Link
                to="/privacy-policy/"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Privacy
              </Link>
            </div>
          </nav>

          <p className="mt-8 text-center text-base text-gray-400">
            &copy; 2023 Khaled Garbaya All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};
export const Head = () => (
  <Seo title="Contentful to Figma Plugin | Design & integrate With Ease" />
);

export default IndexPage;
