import React, { useEffect, useId, useState } from "react";
import LazyLoad from "vanilla-lazyload";
const config = {
  elements_selector: ".lazy",
};
const isBrowser = typeof window !== "undefined";

const LazyVideo = ({ sources, poster, width, height }) => {
  let lazyLoadInstance;
  const uniqId = Math.random().toString(36).substring(7);
  if (isBrowser) {
    [lazyLoadInstance] = useState(() => new LazyLoad(config));
  }
  useEffect(() => {
    lazyLoadInstance.update();
  }, []);

  return (
    <video
      className="lazy w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
      autoPlay
      muted
      loop
      playsInline
      width={width}
      height={height}
      poster={poster}
    >
      {sources.map((source, i) => (
        <source
          key={`${uniqId}-${i}`}
          data-src={source.src}
          type={source.type}
        />
      ))}
    </video>
  );
};

export default LazyVideo;
