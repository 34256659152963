import React, { useEffect, useId, useState } from "react";
import LazyLoad from "vanilla-lazyload";
const config = {
  elements_selector: ".lazy",
};
const isBrowser = typeof window !== "undefined";

const LazyIframe = () => {
  let lazyLoadInstance;
  const uniqId = Math.random().toString(36).substring(7);
  if (isBrowser) {
    [lazyLoadInstance] = useState(() => new LazyLoad(config));
  }
  useEffect(() => {
    lazyLoadInstance.update();
  }, []);

  return (
    <iframe
      className="lazy w-full aspect-video"
      data-src="https://www.youtube.com/embed/M_7ARLu3FQw"
      title="Contentful to Figma overview"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  );
};

export default LazyIframe;
